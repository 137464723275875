import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
//import * as styles from "../styles/components/_email-list-form.scss"

export default function PrivacyPolicy() {
  return (
    <Layout>
      <SEO title="Shopping Tracker" />
      <div className="LegalDoc">
        <h1>Privacy Policy</h1>
        <p>
          Your privacy is just as important to us as it is to you, and that’s
          why we do not use cookies when you visit the website. Once you have
          signed up and logged in, we will make use of the bare minimum required
          to make the app work. This includes a persistent first-party cookie
          stored on your machine. The cookie will be used by the application to
          remember that you are logged in. For more information about cookies
          please have a look <a href="https://www.allaboutcookies.org/">here</a>
          . This helps us to comply with GDPR regulations, as well as the other
          privacy regulations.
          <br />
          <br />
          We firmly believe that your data belongs to you. Any data that you
          provide will be securely kept and encrypted on MongoDB Cloud (see
          below) and will not be sold. This includes any data you may enter into
          the app.
          <br />
          <br />
          We do require an email address on sign-up. This will be for your
          convenience only, should you need to reset your password.
          <br />
          <br />
          In the interest of providing you with the best customer service
          possible, we will securely store all your correspondence with us. This
          just helps us to improve our service and keep track of what we had
          discussed in the past in case you need some help again.
          <br />
          <br />
        </p>
        <h2>Services used</h2>
        <p className="styles.EmailListForm.Text">
          The following services are used by the site: <br />
          <b>Plausible:</b> <br />- Plausible is used for analytical purposes.
          They are a GDPR compliant privacy first analytics service that does
          not use cookies or collect any personal data. Check out their{" "}
          <a href="https://plausible.io/privacy">privacy policy</a> for more
          details. You can also have a look at their{" "}
          <a href="https://plausible.io/data-policy">data policy</a> to see what
          they collect.
          <br />
          <br />
          <b>Heroku:</b> <br />- The web application is hosted on Heroku using
          their EU servers. Their privacy policy can be found{" "}
          <a href="salesforce.com/company/privacy/">here</a>. <br />
          <br />
          <b>Netlify:</b> <br />- This page, the landing page and the
          documentation is hosted on Netlify's Servers. Their privacy policy can
          be viewed <a href="https://www.netlify.com/privacy/">here</a>. <br />
          <br />
          <b>MailChimp:</b> <br />- MailChimp is used for sending the
          newsletter, should you choose to subscribe to it, via email and their
          privacy policy can be found{" "}
          <a href="https://mailchimp.com/legal/privacy/">here</a>. <br />
          <br />
          <b>MongoDB Cloud:</b> <br />- All databases are all stored on the
          MongoDB Cloud. Their servers are hosted in the EU, and their privacy
          policy can be found{" "}
          <a href="https://www.mongodb.com/legal/privacy-policy">here</a>.
          <br />
          <br />
        </p>
        <h2>Retention of data</h2>
        <p className="styles.EmailListForm.Text">
          We will keep your data for as long as your account is active.{" "}
          <b>
            If you delete your account then all your data will be deleted as
            well.
          </b>{" "}
          Please be sure that you want to delete your account, the data cannot
          be recovered once deleted.
          <br />
          <br />
          The exception to this is where we are required by law to retain your
          data, or may need to retain your data in order to continue providing a
          service. This also applies to resolving disputes and enforcing our
          agreements.
          <br />
          <br />
          Please note that although we do delete your data as soon as possible,
          third party providers may take longer to delete your data and that
          your data may persist in backup copies on their servers for a period
          of time as required by their own policies.
          <br />
          <br />
        </p>
        <h2>Downloading your data</h2>
        <p className="styles.EmailListForm.Text">
          You can download all your data at any time from the web application.
          Just go to Settings and then click on "Download my data".
        </p>
        <h2>Contact</h2>
        <p className="styles.EmailListForm.Text">
          Please <a href="/imprint">contact us</a> if you have any questions,
          comments or concerns.
        </p>
      </div>
    </Layout>
  )
}
